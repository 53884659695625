<template>
    <v-app>
        <transition name="fade-in-up">
            <div>
                <div class="card card-custom card-stretch">
                    <div class="card-body container pt-4">
                        <v-form ref="form" v-model="valid" lazy-validation>
                            <div class="row">
                                <div class="col-md-6">
                                    <div class="col-md-12">
                                        <div class="row">
                                            <div class="col-md-9">
                                                <v-autocomplete
                                                    v-model="user"
                                                    :items="users"
                                                    label="Khách hàng"
                                                    item-text="Name"
                                                    item-value="Id"
                                                    return-object
                                                ></v-autocomplete>
                                            </div>
                                            <div class="col-md-3" align="end" style="display: flex;justify-content: center;flex-direction: column;">
                                                <v-dialog v-model="dialog" persistent max-width="600px">
                                                    <template v-slot:activator="{ on, attrs }">
                                                        <v-btn text v-bind="attrs" v-on="on">
                                                            <v-icon small class="mr-2">mdi-plus</v-icon>Thêm khách hàng
                                                        </v-btn>
                                                    </template>
                                                    <v-card>
                                                        <v-card-title>
                                                            <span class="headline">Thêm khách hàng</span>
                                                        </v-card-title>
                                                        <v-card-text>
                                                            <v-container>
                                                                <v-row>
                                                                    <v-col cols="12" sm="12" md="12">
                                                                        <v-text-field v-model="new_user_name" label="Tên KH(*)" required></v-text-field>
                                                                    </v-col>
                                                                    <v-col cols="12" sm="12" md="12">
                                                                        <v-text-field v-model="new_user_phone" label="SĐT(*)" required></v-text-field>
                                                                    </v-col>
                                                                    <v-col cols="12" sm="12" md="12">
                                                                        <v-text-field v-model="new_user_addr" label="Địa chỉ(*)" required></v-text-field>
                                                                    </v-col>
                                                                </v-row>
                                                            </v-container>
                                                            <small>(*)Trường bắt buộc</small>
                                                        </v-card-text>
                                                        <v-card-actions>
                                                            <v-spacer></v-spacer>
                                                            <v-btn color="blue darken-1" text @click="dialog = false">Huỷ</v-btn>
                                                            <v-btn color="blue darken-1" text @click="create_user()">Thêm</v-btn>
                                                        </v-card-actions>
                                                    </v-card>
                                                </v-dialog>
                                                <!--<v-btn @click="show_create_tag()" text><v-icon small class="mr-2">mdi-plus</v-icon>Thêm từ khoá</v-btn>-->
                                            </div>
                                        </div>
                                    </div>

                                    <div class="col-md-12">
                                        <v-text-field
                                            v-model="user_phone"
                                            label="SĐT"
                                        ></v-text-field>
                                    </div>
                                    <div class="col-md-12">
                                        <v-text-field
                                            v-model="user_addr"
                                            label="Địa chỉ"
                                        ></v-text-field>
                                    </div>
                                    <div class="col-md-12">
                                        <v-autocomplete
                                            v-model="product"
                                            :items="products"
                                            label="Sản phẩm"
                                            item-text="Name"
                                            item-value="Id"
                                            return-object
                                        ></v-autocomplete>
                                    </div>
                                    <div class="col-md-12">
                                        <v-text-field
                                            v-model="quantity"
                                            type="number"
                                            label="Số lượng"
                                        ></v-text-field>
                                    </div>
                                    <div class="col-md-12">
                                        <v-text-field
                                            v-model="amount"
                                            type="number"
                                            label="Tổng tiền"
                                        ></v-text-field>
                                    </div>
                                    <div class="col-md-12">
                                        <v-datetime-picker dateFormat="dd-MM-yyyy" label="Thời gian đặt hàng" v-model="time"> </v-datetime-picker>
                                    </div>
                                    <div class="col-md-12">
                                        <!-- <v-text-field
                                            v-model="status"
                                            label="Trạng thái"
                                            type="number"
                                        ></v-text-field> -->
                                        <v-select
                                            v-model="status"
                                            :items="data_status"
                                            label="Trạng thái"
                                            item-text="Name"
                                            item-value="Id"
                                        ></v-select>
                                    </div>
                                    <div v-if="status == 1" class="col-md-12">
                                        <v-datetime-picker dateFormat="dd-MM-yyyy" label="Thời gian giao hàng dự kiến" v-model="expected_time"> </v-datetime-picker>
                                    </div>
                                    <div v-if="status == 5" class="col-md-12">
                                        <v-textarea
                                            v-model="reason_cancel"
                                            label="Lý do huỷ"
                                        ></v-textarea>
                                    </div>
                                </div>
                                <div class="col-md-6">
                                    
                                    
                                </div>
                                
                                <div class="col-md-6">
                                    <v-btn
                                        class="ma-2 float-right"
                                        :loading="loading"
                                        color="info"
                                        @click="create_order"
                                    >
                                        Thêm
                                    </v-btn>
                                    <v-btn
                                        class="ma-2 float-right"
                                        :loading="loading"
                                        @click="cancel"
                                    >
                                        Huỷ
                                    </v-btn>
                                </div>
                            </div>

                        </v-form>
                    </div>
                </div>
            </div>
        </transition>
    </v-app>
</template>


<style lang="scss">
@import "~vuetify/dist/vuetify.css";

.theme--light.v-application {
  background: transparent !important;
}
.v-application code {
  box-shadow: none !important;
}
</style>

<script>
import { SET_BREADCRUMB } from "@/core/services/store/breadcrumbs.module";

export default {
    metaInfo: {
        title: 'Thêm đơn hàng'
    },
    data() {
        return {
            data_status: [
                { Id: 0, Name: "Chờ xác nhận" },
                { Id: 1, Name: "Đã chốt đơn" },
                { Id: 3, Name: "Đã lên đơn" },
                { Id: 5, Name: "Đã huỷ" }
            ],
            valid: true,
            user: {},
            url: [],
            user_phone: "",
            user_addr: "",
            quantity: 1,
            amount: 0,
            product: {},
            status: 0,
            time: new Date(),
            expected_time: null,
            reason_cancel: "",

            // for modal
            dialog: false,
            new_user_phone: "",
            new_user_name: "",
            new_user_addr: "",
            loading: false,
            name_rules: [],
        };
    },
    watch: {
        user(val) {
            this.user_phone = val.Phone_num;
            this.user_addr = val.Adr1 ? val.Adr1 : val.Adr2;
        },
        product(val) {
            this.amount = val.Cost * this.quantity;
        },
        quantity(val) {
            this.amount = this.product.Cost * val;
        }

    },
    computed: {
        users() {
            return this.$store.getters['users/get_users'];
        },
        products() {
            return this.$store.getters['products/get_products'];
        },
    },
    components: {
        
    },
    methods: {
        create_order() {
            this.loading = true;
            var payload = {
                User_id: this.user.Id,
                Reg_phone: this.user_phone,
                Reg_adr: this.user_addr,
                Item_id: this.product.Id,
                Quantity: parseInt(this.quantity),
                Amount: this.amount,
                Order_time: Math.floor(new Date(this.time).getTime() / 1000),
                Status: this.status,
                toast: this.$root.$bvToast,
            }

            if(this.status == 1) {
                payload.Expected_time = Math.floor(new Date(this.expected_time).getTime() / 1000);
            }
            if(this.status == 5) {
                payload.Delete_reason = this.reason_cancel;
            }

            this.$store.dispatch('orders/create_order', payload).then(() => {
                this.loading = false;
                this.$router.push({ name: 'orders-index' })
            }).catch(() => {
                this.loading = false;
            });
        },
        create_user() {
            this.loading = true;
            var payload = {
                Phone_num: this.new_user_phone,
                Name: this.new_user_name,
                Adr1: this.new_user_addr,
                toast: this.$root.$bvToast,
            }
            this.$store.dispatch('users/create_user', payload).then(() => {
                this.dialog = false;
                this.loading = false;
                this.$store.dispatch('users/get_all', {pageSize: 1000, pageIndex: 1});
            }).catch(() => {
                this.dialog = false;
                this.loading = false;
            });
        },
        cancel() {
            this.$router.go(-1);
        },
        browse_file() {
            this.$refs.upload.click();
        },
        async upload_server() {
            // var self = this;
            var files = this.$refs.upload.files;
            if(files) {
                for(var i = 0; i < files.length; i++) {
                    var url = await this.upload_one(files[i]);
                    this.url.push(url);
                }
            }
        },
        async upload_one(file) {
            var res = await this.$store.dispatch('uploads/upload', {file: file});
            return res.data.url;
        },
        delete_images() {
            this.url = [];
        }
    },
    mounted() {
        this.$store.dispatch(SET_BREADCRUMB, [ { title: "Đơn hàng", route: "index" }, { title: "Thêm đơn hàng" } ]);
        this.$store.dispatch('products/get_all', {pageSize: 1000, pageIndex: 1, categoryId: -1});
        this.$store.dispatch('users/get_all', {pageSize: 1000, pageIndex: 1});
    }
};
</script>



